:root {
  color-scheme: light dark;
  --main-bg-color: #eee;
  --main-fg-color: #000;
  --title-bg-color: #CCC;
  --unselected-color: #ccc;
  --table-head-bg-color: #DDD;
  --button-color: #48F;
  --link-color: #36C;
  --code-area-bg-color: #CCC;
  --pre-area-bg-color: #FFF;
  --un-run-bg-color: #8AF;
  --aborted-bg-color: #DDD;
  --error-fg-color: darkred;
  --warn-fg-color: brown;
  --editor-bg-color: #fff;
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.2;
  background: var(--main-bg-color);
  color: var(--main-fg-color);
}
html, body {
  height: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
}
/* ---------------- */

/* ---------------- */
#root {
  height: 100%;
  overflow: auto;
}
.App {
  height: 100%;
}
.error-msg {
  color: var(--error-fg-color);
}
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.head {
  flex: 0 0 auto;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: larger;
}
.head a {
  text-decoration: none;
  color: var(--main-fg-color);
  display: flex;
  align-items: center;
}
.head img {
  height: 1em;
  vertical-align: middle;
  margin: 5px;
}
.head .beta {
  font-size: x-small;
  vertical-align: top;
  position: relative;
  right: 1em;
}
.fix-help-contribute {
  display: flex;
  align-items: center;
}
.fix-help-contribute a {
  text-decoration: none;
  color: var(--main-fg-color);
}
.fix-help-contribute .three-words {
  font-size: x-small;
  text-align: right;
}
.fix-help-contribute .octocat {
  display: flex;
  flex-direction: column;
  justify-content: top;
}
.top {
  flex: 0 0 auto;
  display: flex;
  margin: 10px 10px 0 10px;
}
.bottom {
  display: flex;
  margin: 10px;
  position: relative;
  flex: 1 1 auto;
  min-height: 0;
  height: 100%;
}
.top>div,
.bottom>div {
  flex: 1 1 50%;
  display: flex;
}
.top .right {
  margin-left: 10px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.toolbar {
  display: flex;
}
.top .left {
  position: relative;
  min-height: 0;
  min-width: 0;
  height: 100%;
}
.bottom .left {
  height: 100%;
}
.codes {
  height: 100%;
  min-height: 0;
}
.codes>div {
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
}
.right {
  min-height: 0;
  min-width: 0;
  height: 100%;
}
.bottom  {
  display: flex;
}
/* ---------------------- */
.layout-3-horizontal-top {
  height: 100%;
}
.layout-3-horizontal-top .codes>div {
  flex-direction: row;
}
.layout-3-horizontal-bottom {
  height: 100%;
}
/* ---------------------- */
.layout-tabbed {
  width: 100%;
  height: 100%;
  flex-direction: column; 
}
.layout-tabbed>.split {
  flex: 1 1 auto;
  min-height: 0;
}
.layout-tabbed-result {
  height: 100%;
}
.layout-tabbed-log {
  height: 100%;
}
.layout-tabbed-top .tab-tabs {
  display: flex;
  flex: 0 0 auto;
}
.layout-tabbed .tab-content {
  height: 100%;
}
.layout-tabbed .tab-content>div {
  height: 100%;
}

/* add this below? */
.layout-tabbed-top .hidee {
  flex: 1 1 auto;
}

.layout-tabbed-top .code-area,
.layout-tabbed-top .hidee,
.react-codemirror2 {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.layout-tabbed-top .tab-tabs input {
  position: absolute;
  opacity: 0;
  padding: 0;
  user-select: none;
}
.layout-tabbed-top .tab-tabs label {
  cursor: pointer;
  padding: 0.5em 1em 0.5em 1em;
  margin-right: 3px;
  background: rgba(0,0,0,0.2);
  user-select: none;
}
.layout-tabbed-top .tab-tabs input:checked + label {
  background-color: var(--editor-bg-color);
}
.layout-tabbed .expander {
  display: none;
}

/* ---------------------- */
.logger {
  background: var(--title-bg-color);
  font-family: monospace;
  white-space: pre-wrap;
  height: 100%;
  width: 100%;
}
.logger table {
  width: 100%;
}
.logger td {
  vertical-align: top;
}
.log-messages {
  overflow: auto;
  height: 100%;
}
.log-messages>* {
  overflow-wrap: anywhere;
}
.logger .msg {
  flex: 1 1 auto;
}
.logger .file {
  user-select: none;
  white-space: nowrap;
  color: var(--link-color);
}
.tooltip {
  position: relative;
  border-bottom: 1px dashed #888;
}
.tooltip:before {
  content: attr(data-tooltip);
  position: absolute;
  top: 100%;
  transform: translateY(0%);
  right: 0;
  background: var(--main-bg-color);
  display: none;
  white-space: pre;
  left: initial;
  margin: initial;
  z-index: 2;
}
.tooltip:hover:before {
  display:block;
}
.logger .file-link {
  text-decoration: underline;
}
.logger .file-link:hover {
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.logger .log .count,
.logger .info .count,
.logger .warn .count,
.logger .error .count,
.logger .log .no-count,
.logger .info .no-count,
.logger .warn .no-count,
.logger .error .no-count {
  width: 1.0em;
  height: 1em;
  margin: 0 0.25em 0 0.25em;
  background-position: center center;
  background-repeat: no-repeat;
  flex: 0 0 auto;
  text-align: right;
  white-space: nowrap;
  user-select: none;
}
.logger .log .count,
.logger .info .count,
.logger .warn .count,
.logger .error .count {
  border-radius: 0.5em;
}

.logger .info .count {
  background: #666;
  color: #DDD;
  color: var(--unselected-color);
}
.logger .warn {
  color: var(--warn-fg-color);
  background: rgba(255, 255, 0, 0.2);
}
.logger .warn .count {
  background: yellow;
  color: brown;
}
.logger .warn .no-count {
  background-image: url(/resources/images/warn-icon.svg);
}
.logger .error {
  color: var(--error-fg-color);
  background: rgba(255, 0, 0, 0.2);
}
.logger .error .count {
  background: red;
  color: white;
}
.logger .error .no-count {
  background-image: url(/resources/images/error-icon.svg);
}
/* ---------------------- */
.split {
  display: flex;
  width: 100%;
  height: 100%;
}
.split-vertical {
  flex-direction: column;
}
.split .pane {
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.editor-codemirror .pane {
  /*
   I don't understand why codemirror needs this
   and monaco fails with it. >:(
  */
  overflow: hidden;
}
.split .pane-dragging {
  overflow: hidden;
}
.gutter {
  background-color: var(--main-bg-color);
  flex: 0 0 auto;
}
.gutter.gutter-horizontal,
.gutter.gutter-horizontal:hover {
    cursor: ew-resize;
}
.gutter.gutter-vertical,
.gutter.gutter-vertical:hover {
    cursor: ns-resize;
}
.gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
}
/* ---------------------- */
a {
  color: var(--link-color);
}
.blocked {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* ---------------------- */
.dialog {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  width: 100%;
  height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.dialog>* {
  background: var(--main-bg-color);
  border-radius: 5px;
  padding: 1em;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  width: 800px;
  outline: none;
}
.fixed-size-dialog>* {
  min-height: 90%;
}
.dialog-heading {
  display: flex;
  justify-content: space-between;
  font-size: xx-large;
  font-weight: bold;
  margin: 10px;
  position: relative;
  user-select: none;
}
.dialog-content {
  margin: 10px;
}
.dialog-close {
  position: absolute;
  right: 0;
  top: 0;
  vertical-align: top;
}
.dialog pre {
  width: 100%;
  overflow: auto;
  background: var(--pre-area-bg-color);
  padding: 1em;
}
.section {
  margin-bottom: 10px;
  border: 1px solid #888;
  border-radius: 5px;
  padding: 1em;
}
.section-heading {
  font-size: large;
  border-bottom: 1px solid #888;
  margin-bottom: 1em;
  user-select: none;
}
.save-as-gist-pat {
  display: flex;
  align-items: center;
}
.save-as-gist-pat>* {
  vertical-align: middle;
}
.save-as-gist-pat>div:nth-child(1) {
  flex: 0 0 auto;
}
.save-as-gist-pat>div:nth-child(2) {
  flex: 1 1 auto;
}
.bookmark {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: not-allowed;
}
/* ------------------ */
.messages {
  position: fixed;
  bottom: 0;
  z-index: 1000;
}
.messages>div {
  background: #888;
  color: white;
  padding: 0.5em;
  margin: 0.25em;
  border-radius: 0.25em;
}
.messages .error {
  background: #C00;
}
.messages .info {
  background: #00F;
}
/* -------------------- */
.radio {
  display: flex;
}
.radio-selected {
  background-color: red;
  border-radius: 0.25em;
}
.radio img {
  display: block;
}
/* -------------------- */
.layout .radio>* {
  width: 3.5em;
  padding: 0.5em;
}
/* -------------------- */
.settings .radio>* {
  margin: 0.2em;
  padding: 0.2em;
}
/* -------------------- */
input {
  border: none;
  padding: 0.25em;
  background: var(--title-bg-color);
  color: var(--main-fg-color);
}
.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.name > * + * {
  margin-left: 10px;
}
.name>*:nth-child(1) {
  flex: 1 1 auto;
}
.avatar {
  height: 2em;
  border-radius: 100%;;
}
input[type="text"],
input[type="password"] {
  outline: none;
  width: 100%;
  font-size: large;
}
.named {
  padding: 0.25em;
}
.runner {
  width: 100%;
  height: 100%;
}
.runner>iframe {
  width: 100%;
  height: 100%;
  background: white;
  border: none;
}
.code-area {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: var(--code-area-bg-color);
  min-height: 0;
  height: 100%;
}
.code-area button {
  margin-right: 0;
}
.code-area input {
  background: var(--code-area-bg-color);
  font-size: medium;
}
.expander {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.hider {
  width: 1em;
  color: #888;
}
.hidee {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
.hidee>* {
  flex: 1 1 auto;
}
.hidee-hide {
  height: 0;
  overflow: hidden;
}
button {
  background: none;
  border: 2px solid var(--button-color);
  color: var(--main-fg-color);
  border-radius: 0.25em;
  padding: 0.25em;
  margin-right: 0.25em;
  min-width: 2.5em;
  font-weight: bold;
  user-select: none;
  height: 2.25em;
  line-height: calc(1em - 12px);
}
button img {
  height: 1em;
}
button:hover {
  background: var(--button-color);
}

.react-codemirror2 {
  position: relative;
  height: 100%;
}
.CodeMirror {
  width: 100%;
  height: 100%;
}
.CodeMirror-overlayscroll-horizontal div,
.CodeMirror-overlayscroll-vertical div {
  opacity: 0.6;
}
.markdown code {
  background: var(--title-bg-color);
  padding: 0.25em;
  border-radius: 0.5em;
  line-height: 2;
}
.gists {
  background-color: rgba(0, 0, 255, 0.1);
  border: none;
  display: block;
  min-height: 20vh;
  max-height: 40vh;
  overflow-y: scroll;
  position: relative;
}
.gists table {
  width: 100%;  
}
.gists thead {
  background-color: var(--title-bg-color);
  border: none;
}
.gists th {
  position: sticky; 
  top: 0;
  background-color: var(--title-bg-color);
}
.gists thead span {
  cursor: pointer;
  color: var(--unselected-color);
}
.gists thead .current-sort-key {
  color: var(--main-fg-color);
}
.gists td>div {
  transition: max-height 0.3s;
  max-height: 100%;
}
.gists .hide td>div {
  max-height: 0;
}
.gists tr:nth-child(2n) {
  background-color: rgba(128, 160, 255, 0.2);
}
.gists td:nth-child(3n) {
  text-align: right;
  white-space: nowrap;
}

.comment-notes {
  padding: 1em;
  margin: 1em;
  background: rgba(255, 255, 255, 0.5);
  color: red;
}
.comments,
.comment-sep {
    margin: 0 auto 1em;
    max-width: 700px;
    width: calc(100% - 40px);
}
.comments iframe {
  width: 100%;
  border: none;
}
.comments .links {
  text-align: center;
}
hr {
  border: none;
  background: var(--title-bg-color);
  height: 2px;
}
/*-------------*/
.logout {
  display: flex;
  align-items: center;
}
/*-------------*/
.new-comment {
  border-radius: 0.5em;
  border: 1px solid var(--code-area-bg-color);
  margin-left: calc(0.5em + 42px);
  margin-right: 0.5em;
  margin-bottom: 15em;
  position: relative;
}
.new-comment .new-comment-head {
  background: var(--code-area-bg-color);
  padding: 0.5em;
}
.new-comment .new-comment-head .user-avatar {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-42px);
}
.new-comment .react-codemirror2 {
  padding: 0 0.5em 0.5em 0.5em;
  height: 10em;
  resize: vertical;
  overflow: auto;
}
.new-comment .new-comment-write {
  min-height: 10em;
  height: 10em;
}
.new-comment .new-comment-preview {
  min-height: 10em;
  padding: 0.5em;
}
.new-comment .new-comment-tabs {
  margin-top: 1em;
}
.new-comment input[type="radio"] {
  opacity: 0;
  width: 0;
}
.new-comment input + label {
  padding: 0.5em 0.5em 0 0.5em;
  margin-right: 0.125em;
  background: rgba(0,0,0,0.1);
}
.new-comment input:checked + label {
  background: var(--editor-bg-color);
}
.new-comment-submit {
    display: flex;
    justify-content: flex-end;
    padding-top: 1em;
}
.copy-text {
  position: relative;
}
.copy-text .copy-buttons {
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 740px) {
  .head {
    font-size: medium;
    padding: 2px;
  }
  .head .beta {
    font-size: xx-small;
  }
  .top,
  .bottom {
    flex-direction: column;
  }
  .top, .bottom {
    margin-top: 5px;
  }
  .top {
    flex-direction: column-reverse;
  }
  .right {
    margin-left: 0;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  .top .right {
    margin-left: 0;
    margin-top: 5px;
  }
  .fix-help-contribute .three-words {
    display: none;
  }
}

@media (max-width: 540px) {
  html {
    font-size: xx-small;
  }
  input[type="text"], input[type="password"] {
      outline: none;
      width: 100%;
      font-size: small;
  }
  button {
    font-size: small;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: #444;
    --main-fg-color: #fff;
    --unselected-color: #666;
    --title-bg-color: #222;
    --button-bg-color: #fff;
    --link-color: #6CF;
    --table-head-bg-color: #555;
    --code-area-bg-color: #555;
    --pre-area-bg-color: #222;
    --un-run-bg-color: blue;
    --aborted-bg-color: #000;
    --error-fg-color: red;
    --warn-fg-color: yellow;
    --editor-bg-color: #263238;
  }
  .CodeMirror-overlayscroll-horizontal div,
  .CodeMirror-overlayscroll-vertical div {
    opacity: 0.3;
  }
  .comment-notes {
    background: rgba(0, 0, 0, 0.5);
  }
  .gists {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .gists tr:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
